import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {environment} from '../../../../environments/environment';
import {ConsumersService} from '../../../shared/services/consumers.service';

@Component({
    template: '',
    selector: 'app-account-sponsorship'
})
export class SponsorshipComponent {
    constructor(
        private readonly router: Router,
        private readonly marketplaceService: MarketplaceService,
        private readonly consumersService: ConsumersService
    ) {
        if (marketplaceService.sponsorshipSettings?.online) window.open(this.getUrl(this.marketplaceService.sponsorshipSettings?.domain));
        // Always redirect to home
        this.router.navigate(['/home']).then();
    }

    getUrl(url: string | undefined): string {
        if (!url) url = environment.sponsorshipUrl;
        if (!url.startsWith('http')) url = `https://` + url;

        return `${url}/auth/?token=${this.consumersService.consumer.purlToken}&route=/parrain/inviter`;
    }
}
