import {Component} from '@angular/core';
import {AuthenticationService, CacheService, MarketplaceSettings} from '@isifid/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {environment} from '../../../../environments/environment';
import {UiService} from '../../../shared/services/ui.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent {
    settings?: MarketplaceSettings;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly cacheService: CacheService,
        private readonly marketplaceService: MarketplaceService,
        private readonly router: Router,
        public uiService: UiService
    ) {
        // If the user is auth, redirect to home
        if (this.authenticationService.isAuthenticated()) this.router.navigate(['/home']).then();
        else this.initTemplate();
    }

    initTemplate(): void {
        this.marketplaceService.getSettingsByDomain().subscribe({
            next: (settings) => {
                // Logout if we couldn't find the template
                if (!settings) this.router.navigate(['/logout']).then();
                this.settings = settings;
            }, error: () => {
                console.error('error while getting settings by template');
                this.router.navigate(['/logout']).then();
            }
        });
    }

    logo(): string {
        if (this.settings?.logoUuid) return `${environment.cdnUrl}/static/${this.settings.logoUuid}.png`;
        else return '';
    }
}
