import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService, CacheService} from '@isifid/core';
import {BootstrapService} from '../../../shared/services/bootstrap.service';
import {ConsumersService} from '../../../shared/services/consumers.service';

@Component({
    selector: 'app-loader',
    templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
    consumerId: number | null | undefined;
    emailType: string | null | undefined;
    provider: string | null | undefined;
    rewardId: string | null | undefined;
    token: string | null | undefined;
    uuid: string | null | undefined;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly bootstrapService: BootstrapService,
        private readonly cacheService: CacheService,
        private readonly consumersService: ConsumersService,
        private readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.parseUrl();
        this.dispatch();
    }

    // Parse the url, no args are available when auth component is called
    parseUrl(): void {
        const urlParams = new URLSearchParams(window.location.search);
        this.provider = urlParams.get('provider') ?? 'default';
        this.token = urlParams.get('token');
        this.consumerId = Number(urlParams.get('consumerId'));
        this.uuid = urlParams.get('uuid');
        this.rewardId = urlParams.get('rewardId');
        this.emailType = urlParams.get('emailType');

        const pathname = window.location.pathname.replace('//', '/');
        if (pathname.startsWith('/demo')) {
            this.provider = 'demo';
        } else if (pathname.startsWith('/email')) {
            this.cacheService.addPermanentContent('return_url', `/account/email-display?rewardId=${this.rewardId}&emailType=${this.emailType}`, false);
        }
    }

    dispatch(): void {
        switch (this.provider) {
        case 'isifid':
            this.isifid();
            break;
        case 'default':
            this.default();
            break;
        case 'demo':
            this.demo();
            break;
        default:
            this.redirectTo('/logout');
            break;
        }
    }

    isifid(): void {
        // Check we have a valid consumerId and a token
        const consumerId = Number(this.consumerId);
        if (isNaN(consumerId) || consumerId === 0 || !this.token) {
            this.redirectTo('/logout');
            return;
        }

        this.authenticationService.loginBySso('admin', this.token, '', consumerId.toString())
            .subscribe({next: () => this.initAfterAuth(), error: () => this.redirectTo('/logout')});
    }

    default(): void {
        if (!this.token) return this.redirectTo('/logout');

        // Letter and number only
        const tokenRegex = /^[a-zA-Z0-9]+$/;
        const valid = tokenRegex.test(this.token);
        if (!valid) return this.redirectTo('/logout');

        // Progressively remove old system
        // Can be remove after 2024/6/1

        this.authenticationService.loginByConsumerToken(
            this.authenticationService.getPurlTokenFromConcealedPurlToken(this.token) ?? this.token
        )
            .subscribe({
                next: (tokens: any) => {
                    // When a token already exists in cache
                    // we check that it belongs to the same user
                    if (this.authenticationService.getRefreshToken()) {
                        try {
                            const newConsumerId = JSON.parse(window.atob(tokens.refreshToken.split('.')[1]))?.consumerId;
                            // Both uuid are different so we flush current tokens
                            if (!newConsumerId || this.consumersService.getConsumer().id !== newConsumerId) this.authenticationService.logout(false, false);
                        } catch {
                            this.redirectTo('/logout');
                        }
                    }

                    // Init with tokens
                    this.authenticationService.initWithTokens(tokens).subscribe();
                    this.initAfterAuth();
                },
                error: () => this.redirectTo('/logout')
            });
    }

    demo() {
        // If we refresh the page on path /demo, if the demo mode has already been initialized, we let the bootstrap service handle it directly
        this.initAfterAuth();
    }

    redirectTo(path: string): void {
        this.router.navigateByUrl(path).then();
    }

    private initAfterAuth(): void {
        let returnUrl = this.cacheService.getContent('return_url', false);
        this.cacheService.deleteContent('return_url');

        if (!returnUrl || returnUrl.includes('/log/r32/') || returnUrl.includes('/auth')) returnUrl = '/home';

        // Redirect when app is initialised
        this.bootstrapService.isInitialised.subscribe({
            next: (isInitialised) => {
                if (isInitialised) this.redirectTo(returnUrl);
            }
        });
    }
}
