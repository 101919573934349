import {Component, OnInit} from '@angular/core';
import {DateService, LabelledReward, Order, Partner, PartnerOffer, Voucher} from '@isifid/core';
import {ConsumersService} from '../../../shared/services/consumers.service';
import {PartnersService} from '../../../shared/services/partners.service';
import {UiService} from '../../../shared/services/ui.service';
import {DialogService} from '../../../shared/services/dialog.service';
import {catchError, concat, forkJoin, map, Observable, of, switchMap, tap, toArray} from 'rxjs';
import {BootstrapService} from '../../../shared/services/bootstrap.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
    displayOrders: boolean = true;
    orders: Array<Order> = [];
    rewards: Array<LabelledReward> = [];
    partners: Array<Partner> = [];
    partnerOffers: Array<PartnerOffer> = [];
    private vouchers: Array<Voucher> = [];

    constructor(
        private readonly bootstrapService: BootstrapService,
        private readonly consumersService: ConsumersService,
        public readonly dateService: DateService,
        private readonly dialogService: DialogService,
        public readonly partnersService: PartnersService,
        public readonly uiService: UiService
    ) {
    }

    ngOnInit() {
        this.bootstrapService.isInitialised.subscribe((isInitialised) => {
            if (isInitialised) {
                this.orders = this.consumersService.orders.filter(order => order.status !== 'cancelled');
                this.rewards = this.consumersService.rewards.filter(reward => reward.status !== 'cancelled');
                this.initPartnerServiceData().subscribe({error: () => this.uiService.handleError()});
                if (this.orders.length === 0) this.displayOrders = false;
            }
        });
    }

    initPartnerServiceData(): Observable<PartnerOffer[]> {
        const vouchersObs: Observable<Voucher>[] = [];
        const partnersObs: Observable<Partner>[] = [];

        for (const order of this.orders) {
            vouchersObs.push(this.partnersService.getVoucher(order.voucherId).pipe(catchError(() => of(null as unknown as Voucher))));
            partnersObs.push(this.partnersService.getPartnerFromApi(order.partnerId).pipe(catchError(() => of(null as unknown as Partner))));
        }

        return forkJoin([
            forkJoin(partnersObs),
            forkJoin(vouchersObs)
        ]).pipe(
            map(([partners, vouchers]) => {
                this.partners = partners.filter(s => s);
                this.vouchers = vouchers.filter(s => s);
                return this.vouchers.map(s => {
                    return this.partnersService.getPartnerOfferFromApi(s.partnerOfferId)
                        .pipe(catchError(() => of([])));
                });
            }),
            switchMap(observers => concat(...observers).pipe(toArray())),
            map(offers => offers.filter(s => s.length > 0).map(s => s[0])),
            tap(offers => this.partnerOffers = [...this.partnerOffers, ...offers])
        );
    }

    sendOrderEmail(element: any, orderId: number) {
        element.textContent = 'Email envoyé';
        element.disabled = true;
        this.consumersService.sendOrderEmail(orderId).subscribe({error: () => this.uiService.handleError()});
    }

    openHistoryOrder(order: Order) {
        this.dialogService.closeHistoryOrderCardDialog();
        // Only open if screen <= 992px
        if (window.innerWidth <= 992) {
            this.dialogService.openHistoryOrderCardDialog(
                order,
                this.getOrderAmount(order),
                this.getVoucherCode(order.voucherId),
                this.getVoucherSecret(order.voucherId),
                this.dateService.computeDate(order.createdAt, 'dd/MM/yyyy', 30)
            );
        }
    }

    openHistoryReward(reward: LabelledReward) {
        this.dialogService.closeHistoryRewardCardDialog();
        // Only open if screen <= 992px
        if (window.innerWidth <= 992) {
            this.dialogService.openHistoryRewardCardDialog(reward);
        }
    }

    getPartner(partnerId: number) {
        return this.partners.find((partner: Partner) => partner.id === partnerId);
    }

    getPartnerOffer(offerId: number) {
        return this.partnerOffers.find((offer: PartnerOffer) => offer.id === offerId);
    }

    getVoucher(voucherId: number) {
        return this.vouchers.find((voucher: Voucher) => voucher.id === voucherId);
    }

    getVoucherCode(voucherId: number): string {
        const voucher = this.getVoucher(voucherId);
        return voucher ? voucher.code : '';
    }

    getVoucherSecret(voucherId: number): string {
        const voucher = this.getVoucher(voucherId);
        return voucher?.secret ? voucher.secret : '';
    }

    getOrderAmount(order: Order): number {
        const voucher = this.getVoucher(order.voucherId);
        if (voucher) {
            const partnerOffer = this.getPartnerOffer(voucher.partnerOfferId);
            if (partnerOffer) return partnerOffer.price;
        }
        return 0;
    }

    protected readonly environment = environment;
}
