<div class="support min-vp-100 px-4 px-md-5 color-dark-grey">
    <div class="my-5 text-title bold">Assistance</div>

    <div class="row mb-3">
        <p class="col">Voici la liste des questions les plus fréquemment posées. Si vous ne trouvez pas la réponse à
            votre interrogation, vous pouvez nous contacter directement en bas de page.
        </p>
    </div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Comment utiliser ma carte cadeau ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Il vous suffit de sélectionner l’offre qui vous intéresse parmi les enseignes partenaires. Après avoir
                cliqué sur “commander” puis valider votre choix, un e-mail contenant votre code cadeau vous sera envoyé
                par mail. Vous aurez également la possibilité de le retrouver dans l’historique de votre espace de
                récompense.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Je n’ai pas reçu mon code cadeau / j’ai supprimé le mail de commande.
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Nous vous invitons dans un 1er temps à consulter la rubrique « Mes infos » afin de vérifier que
                l'adresse e-mail enregistrée est valide. Si l'adresse indiquée est correcte, merci de vérifier si
                l'e-mail mentionnant votre récompense ne figure pas dans le dossier SPAM ou indésirable de votre boîte
                de messagerie. Dans le cas d'une adresse e-mail erronée, vous pouvez nous contacter par e-mail à
                l'adresse <a href="mailto:{{emailSupport}}">{{emailSupport}}</a>, en rappelant vos nom, prénom, adresse
                e-mail ainsi que le nom de l'enseigne concernée par votre demande afin que votre code cadeau vous soit
                renvoyé. Vous aurez également la possibilité de visualiser le code dans l’historique et de le réexpédier
                par mail si besoin.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Puis-je commander plusieurs codes cadeaux auprès d’une même enseigne ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Vous pouvez bénéficier d'un seul code par enseigne dans la limite des stocks disponibles.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Je me suis trompé, puis-je annuler la commande de mon code cadeau ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Malheureusement, toute commande validée depuis votre espace personnel ne peut donner lieu à une
                annulation ou à un échange. Nous vous invitons donc à consulter le détail de chaque offre et ses
                conditions d'utilisation avant de valider votre prochain choix.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Mon code cadeau ne fonctionne pas, comment faire ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Nous vous invitons à vérifier que vous avez bien respecté les conditions d'utilisation de votre code
                figurant dans votre mail de commande. En cas de dysfonctionnement avéré après vérification des modalités
                d'utilisation, nous vous invitons à nous contacter par mail à l'adresse
                <a href="mailto:{{emailSupport}}">{{emailSupport}}</a> en nous transmettant une copie écran de votre
                panier d’achat effectué sur le site de l’enseigne partenaire.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Aucune enseigne ne m’intéresse, puis-je offrir ma récompense ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Absolument. Vous avez la possibilité de transmettre à un proche l'e-mail, SMS ou document d'accès à
                votre espace de récompense.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Quelle est la durée de validité de mon code cadeau ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                La durée de validité d'un code cadeau est de 1 mois à compter de la date de sa commande sur le site.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Puis-je remplacer ma récompense en e-carte cadeau par de l’argent sur mon compte ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Votre récompense n’est pas convertible en équivalent monétaire.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Les codes cadeaux prennent-ils en compte les frais de port ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Le code cadeau ne couvre pas le règlement des frais de port payant. Cette modalité est précisée sur la
                page de chaque enseigne partenaire disponible sur le site ainsi que dans l'e-mail mentionnant votre
                code.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    J’ai annulé ma commande sur le site d’une enseigne, qu’en est il de mon code cadeau ?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Si votre code cadeau n'est pas réutilisable après une annulation de commande ou un remboursement (les
                codes ne sont pas remboursables), nous vous invitons à en informer notre service client par e-mail. Dès
                confirmation de l'annulation de votre commande auprès de l'enseigne concernée, le montant correspondant
                au code utilisé sera crédité sur votre espace personnel afin que vous puissiez à nouveau sélectionner un
                code.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Je ne trouve pas d’enseignes qui me plaisent.
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                La liste des enseignes évolue régulièrement en fonction des partenariats mis en place. Nous vous
                invitons à parcourir les différents univers du site pour trouver ce que vous recherchez.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-normal bold">
                    Certaines enseignes ne sont pas disponibles pendant les soldes.
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Généralement les codes cadeaux ne sont pas valables sur les articles en soldes ou en promotion. Il
                faudra patienter jusqu’à la fin de cette période pour utiliser votre code cadeau.
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    @if (supportForm) {
        <form (ngSubmit)="submit()" [formGroup]="supportForm">
            <div class="row mt-5">
                <div class="col-12 col-lg-4">
                    <p class="mb-3">Vous avez une autre question ? Notre équipe est là pour vous répondre !</p>
                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Mon problème concerne</mat-label>
                            <mat-select formControlName="subject">
                                <mat-option [value]="'voucher'">J'ai un problème avec un code</mat-option>
                                <mat-option [value]="'reward'">J'ai un problème avec une récompense</mat-option>
                                <mat-option [value]="'other'">J'ai un autre problème</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    @if (supportForm.get('subject')?.value === 'reward') {
                        <div>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Sélectionner la récompense concernée</mat-label>
                                <mat-select formControlName="reward">
                                    @for (reward of rewards; track reward.id) {
                                        @if (reward.label) {
                                            <mat-option [value]=reward.id>{{reward.label}}</mat-option>
                                        }
                                    }
                                    <mat-option [value]="">Autre récompense</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    }

                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <label class="d-none" for="email">Email </label>
                            <mat-label>Email</mat-label>
                            <input formControlName="email"
                                   id="email"
                                   matInput
                                   maxlength="50"
                                   placeholder="email"
                                   type="email">
                        </mat-form-field>
                    </div>
                </div>

                <div class="col">
                    @if (supportForm.get('subject')?.value) {
                        <mat-form-field appearance="fill" class="w-100">
                            <label class="d-none" for="message">Entrez ici votre message à l’intention du support</label>
                            <mat-label class="text-normal">Entrez ici votre message à l’intention du support</mat-label>
                            <textarea formControlName="message" id="message" matInput rows="15"></textarea>
                        </mat-form-field>
                    } @else {
                        <mat-form-field appearance="fill" class="w-100">
                            <label class="d-none" for="messageDisabled">
                                Merci de choisir une raison de contacter le support
                            </label>
                            <textarea disabled id="messageDisabled" matInput rows="15">Merci de choisir une raison de contacter le support via “Mon problème concerne. &#13;&#10;&#13;&#10; Puis : Entrez ici votre message à l’intention du support</textarea>
                        </mat-form-field>
                    }
                </div>
            </div>

            <div class="row mb-4">
                <div class="col text-end">
                    @if (!loading) {
                        <button [disabled]="supportForm.invalid"
                                class="btn-validate px-3 py-2 clickable text-normal"
                                mat-raised-button>
                            Envoyer
                        </button>
                    } @else {
                        <mat-spinner class="mx-auto my-2" />
                    }
                </div>
            </div>
        </form>
    }
</div>
