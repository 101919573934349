import {Component, OnInit} from '@angular/core';
import {MarketplaceSlide} from '@isifid/core';
import {Router} from '@angular/router';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {UiService} from '../../../shared/services/ui.service';
import {SLIDE_LINK} from '../../../shared/models/banner.model';
import {ScrollerService} from '../../../shared/services/scroller.service';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    slides: Array<MarketplaceSlide> = [];

    constructor(
      private readonly marketplaceService: MarketplaceService,
      private router: Router,
      private readonly scrollerService: ScrollerService,
      public uiService: UiService
    ) {
    }

    ngOnInit() {
        this.slides = this.marketplaceService.slides;
    }

    // Get link to redirect when click slide
    public loadingPartners(linkUrl: string | SLIDE_LINK): void {
        if (linkUrl === SLIDE_LINK.SPONSORSHIP_ONLINE_LINK) {
            this.router.navigate(['/account/become-sponsor']).then();
        } else {
            this.scrollerService.scrollToPartnersSection(Number(linkUrl.split('?tagId=')?.[1]));
        }
    }
}
