@if (partners.length || products.length) {
    <div>
        @if (partners.length) {
            <div class="d-flex justify-content-between align-items-end mb-0 px-2 px-md-1 pb-4 color-dark-grey bold">
                <div class="tag-name">{{ tag?.name }}</div>
                @if (scrollValuePartners) {
                    <mat-progress-bar [value]="scrollValuePartners"
                                      class="progress-bar-partners border-radius-5"
                                      color="accent"
                                      mode="determinate"/>
                }
            </div>
            <div class="cdk-virtual-scroll-selected-partners position-relative px-2 clickable">

                @if (!partnersSwipeChevronHideLeft) {
                    <div (click)="swipePartners(-1)"
                         (mouseenter)="dialogService.closeGiftCardDialog()"
                         class="arrow-left">
                        <i class="fa fa-angle-left color-white"></i>
                    </div>
                }

                <div #selectedPartnersContent
                     (scroll)="scrolledIndexPartnersChange()"
                     class="selected-partners">
                    @for (partner of partners; track partner.id) {
                        <app-gift-card
                                (click)="clickGiftCard(partner)"
                                (mouseleave)="unsubscribe()"
                                (mouseenter)="openGiftCard(partner, $event)"
                                [partner]="partner"/>
                    }
                </div>

                @if (scrollValuePartners < 100) {
                    <div (click)="swipePartners(1)"
                         (mouseenter)="dialogService.closeGiftCardDialog()"
                         class="arrow-right">
                        <i class="fa fa-angle-right color-white"></i>
                    </div>
                }
            </div>
        }

        @if (products.length) {
            <div (mouseenter)="dialogService.closeAll()"
                 class="d-flex justify-content-end mb-0 px-2 px-md-1 py-2 py-lg-4 color-dark-grey bold">
                @if (scrollValueProducts) {
                    <mat-progress-bar [value]="scrollValueProducts"
                                      class="progress-bar-products border-radius-5"
                                      color="accent"
                                      mode="determinate" />
                }
            </div>

            <div class="cdk-virtual-scroll-selected-products position-relative px-2 clickable">
                @if (!productsSwipeChevronHideLeft) {
                    <div (click)="swipeProducts(-1)"
                         (mouseenter)="dialogService.closeProductCardDialog()"
                         class="arrow-left">
                        <i class="fa fa-angle-left color-white"></i>
                    </div>
                }

                <div #selectedProductContent
                     (scroll)="scrolledIndexProductsChange()"
                     class="selected-products">
                    @for (product of products; track product.id) {
                        <app-product-card
                                (click)="clickProductCard(product)"
                                (mouseleave)="unsubscribe()"
                                (mouseenter)="openProductCard(product, $event)"
                                [product]="product"/>
                    }
                </div>

                @if (scrollValueProducts < 100) {
                    <div (click)="swipeProducts(1)"
                         (mouseenter)="dialogService.closeProductCardDialog()"
                         class="arrow-right">
                        <i class="fa fa-angle-right color-white"></i>
                    </div>
                }
            </div>
        }
    </div>
}
