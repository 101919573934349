import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LegalNoticeComponent} from './legal-notice/legal-notice.component';
import {TermsComponent} from './terms/terms.component';
import {TrackGuard} from '@isifid/core';
import {CookiesComponent} from './cookies/cookies.component';
import {LayoutNormalComponent} from 'src/app/shared/components/layouts/layout-normal/layout-normal.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutNormalComponent,
        children: [
            {
                path: 'legal-notice',
                canActivate: [TrackGuard],
                component: LegalNoticeComponent
            },
            {
                path: 'terms-and-conditions',
                canActivate: [TrackGuard],
                component: TermsComponent
            },
            {
                path: 'cookies',
                canActivate: [TrackGuard],
                component: CookiesComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LegalRoutingModule {
}
