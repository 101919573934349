<!--  Desktop  -->
<span class="color-dark-grey pt-lg-5"
     [ngClass]="{'sidenav': isDesktop}">
    @if (isDesktop) {
        <div class="text-center px-4">
            <div class="available-amount">Votre montant disponible :</div>
        </div>

        <div class="balance w-100 color-black position-relative bold d-flex align-items-center px-5 pt-2">
            <div class="balance-amount mt-2 mb-1 w-100 text-center">{{ consumersService.getConsumer().balance || 0 }} €</div>
            @if (expiresAt) {
                <div class="question-mark clickable bg-dark-grey-75 circle color-white bold text-center" (click)="dialogService.openHowItWorksDialog()">
                    ?
                </div>
            }
        </div>
        @if (expiresAt) {
            <div class="expires-at mb-4 pt-1 px-4">Valable jusqu'au : {{expiresAt | date: 'dd/MM/yyyy'}}</div>
        }
    }

    @if (!isDesktop && (showMobileBanner$ | async)) {
        <div class="d-flex px-2 pb-1">
            <div class="g-0 amount-section">
                <div class="d-flex flex-wrap justify-content-center align-items-center">
                    <div class="mb-0 text-center w-100 available-amount">
                        Votre montant disponible:
                    </div>
                    <div class="balance w-100 color-black position-relative bold d-flex align-items-center px-3">
                        <div class="balance-amount w-100 text-center">{{ consumersService.getConsumer().balance || 0 }} €</div>
                        @if (expiresAt) {
                            <div class="question-mark clickable bg-dark-grey-75 circle color-white bold text-center"
                                 (click)="dialogService.openHowItWorksDialog()">
                                ?
                            </div>
                        }
                    </div>
                    @if (expiresAt) {
                        <div class="expires-at">Valable jusqu'au : {{expiresAt | date: 'dd/MM/yyyy'}}</div>
                    }
                </div>
            </div>
            <app-banner class="ms-2"/>
        </div>
    }

    <app-sidenav-tags/>
</span>
