<div class="min-vp-100 py-5 px-4 px-md-5 color-dark-grey text-center">
    <div class="text-title color-black bold">Mes infos</div>
    <div class="pt-5 text-normal color-black">
        @if (personalInfosForm) {
            <form (ngSubmit)="update()" [formGroup]="personalInfosForm">
                <div class="mb-4">
                    <mat-form-field class="w-30">
                        <mat-label>Civilité</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option value="3"></mat-option>
                            <mat-option value="1">M.</mat-option>
                            <mat-option value="2">Mme.</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mb-4">
                    <mat-form-field appearance="fill">
                        <label class="d-none" for="firstName">Prénom </label>
                        <mat-label>Prénom</mat-label>
                        <input formControlName="firstName"
                               id="firstName"
                               matInput
                               maxlength="30"
                               placeholder="prénom"
                               type="text">
                    </mat-form-field>
                </div>
                <div class="mb-4">
                    <mat-form-field appearance="fill">
                        <label class="d-none" for="lastName">Nom </label>
                        <mat-label>Nom</mat-label>
                        <input formControlName="lastName"
                               id="lastName"
                               matInput
                               maxlength="50"
                               placeholder="nom"
                               type="text">
                    </mat-form-field>
                </div>
                <div class="mb-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Email</mat-label>
                        <input formControlName="email"
                               id="email"
                               matInput
                               placeholder="Email"
                               type="email">
                    </mat-form-field>
                </div>
                <div class="mb-4">
                    <mat-form-field appearance="fill">
                        <label class="d-none" for="mobile">Mobile </label>
                        <mat-label>Mobile</mat-label>
                        <input class="form-control"
                               formControlName="mobile"
                               id="mobile"
                               matInput
                               type="tel">
                    </mat-form-field>
                </div>
                @if (checkedMobileCold) {
                    <div class="mb-4 color-dark-grey">
                        Opposition au démarchage téléphonique : Vous pouvez vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique Bloctel.
                         Pour en savoir plus sur les modalités d'inscription, consultez le site Internet www.bloctel.gouv.fr
                    </div>
                }
                <div>
                    <button class="btn-validate py-4 color-white mb-5"
                            color="primary"
                            mat-raised-button
                            type="submit">
                        Mettre à jour
                    </button>
                </div>
            </form>
        }
    </div>
</div>
