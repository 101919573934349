<div class="d-flex carousel slide h-100 ps-lg-1 pe-lg-2 mb-lg-4" data-bs-ride="carousel" id="slidesCarousel">
    @if (slides.length > 1) {
        <button class="carousel-control-next d-block d-lg-none rounded-circle opacity-100 me-1"
                type="button" data-bs-target="#slidesCarousel" data-bs-slide="next">
            <i class="fa fa-angle-right bold"></i>
        </button>
        <div class="carousel-indicators d-none d-lg-flex">
            @for (s of slides; track s.id; let index = $index) {
                <button [attr.data-bs-slide-to]="index"
                        [class.active]="index === 0"
                        class="btn-circle mx-1"
                        data-bs-target="#slidesCarousel" type="button">
                </button>
            }
        </div>
    }
    <div class="carousel-inner h-100">
        @for (slide of slides; track slide.id; let index = $index) {
            <div [class.active]="index === 0" class="carousel-item h-100">
                <div (click)="loadingPartners(slide.linkUrl)" class="clickable h-100">
                    <img priority
                         height="117"
                         width="600"
                         alt="Slide {{slide.title}}"
                         [ngSrc]="slide.imgUrl"
                         (error)="uiService.handleMissingImage($event)"/>
                    <div class="carousel-title w-100 position-absolute d-flex align-items-center color-white">
                        <span class="px-4 color-white title">{{ slide.title }}</span>
                        <span class="px-4 lh-2 text-large bold d-none d-lg-flex">Découvrez-en plus</span>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
