import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppPostRoutingModule, AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {registerLocaleData} from '@angular/common';
import {AuthGuard, CapitalizePipe, CoreModule, DateService, HttpJwtInterceptor, LocalErrorHandler, MobileService, TrackGuard} from '@isifid/core';
import {AccountModule} from './modules/account/account.module';
import {AccountRoutingModule} from './modules/account/account-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// Add FR as locale
import localeFr from '@angular/common/locales/fr';
import * as Sentry from '@sentry/angular';
import {SharedModule} from './shared/shared.module';
import {LegalRoutingModule} from './modules/legal/legal-routing.module';
import {CatalogueModule} from './modules/catalogue/catalogue.module';
import {UiService} from './shared/services/ui.service';
import {BootstrapService} from './shared/services/bootstrap.service';
import { HomeModule } from './modules/home/home.module';

registerLocaleData(localeFr, 'fr');

Sentry.init({
    dsn: environment.sentryUrl,
    integrations: [],
    ignoreErrors: [
        'Non-Error exception captured'
    ],
    beforeSend(event): any {
        // Update the environment
        if (environment.production) event.environment = 'production';
        else if (window.location.origin.startsWith('http://localhost:')) return null;
        else if (environment.apiUrl.indexOf('.dev.') !== -1) event.environment = 'development';
        else event.environment = 'staging';
        event.release = environment.version;
        return event;
    }
});

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule.forRoot(environment),
        // Account
        AccountModule,
        AccountRoutingModule,
        // Catalogue
        CatalogueModule,
        // Home
        HomeModule,
        // Legal
        LegalRoutingModule,
        // Shared
        SharedModule,
        // Must be last to catch 404
        AppPostRoutingModule
    ], 
    providers: [
        BootstrapService,
        CapitalizePipe,
        DateService,
        MobileService,
        UiService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpJwtInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: LocalErrorHandler
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        TrackGuard,
        AuthGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
