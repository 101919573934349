import {Component} from '@angular/core';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {AuthenticationService} from '@isifid/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './legal-notice.component.html'
})
export class LegalNoticeComponent {
    constructor(
        public readonly marketplaceService: MarketplaceService,
        public readonly authenticationService: AuthenticationService
    ) {
    }
}
