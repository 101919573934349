<h1 class="text-title text-center my-5">Politique relative aux cookies</h1>

<div class="container mb-5 px-2 px-lg-5 text-justify text-normal">
    <br>
    <p>
        Le présent site édité et hébergé par ISIFID utilise des cookies et traceurs.
    </p>
    <br><br>

    <h3>1. Qu'est-ce qu'un cookie ?</h3>
    <p>
        Un "cookie" est une suite d'informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y reconnecterez.
        <br>
        Pour en savoir davantage sur le sujet, vous pouvez consulter le site de la CNIL accessible <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi">ici</a>
        <br>
        Les cookies peuvent avoir de nombreuses fonctions. Nous les utilisons pour permettre le bon fonctionnement de notre site Internet et de notre application mobile, mesurer anonymement leur audience.
    </p>
    <br><br>

    <h3>2. Quels sont les différents types de cookies que nous utilisons et pour quelles finalités ?</h3>
    <p>
        Le type de cookies utilisés sur notre Site est le suivant :
        <br><br>
        Cookies de mesure d’audience
        <br><br>
        Il s'agit des cookies qui nous servent à établir des statistiques rendues anonymes pour mesurer la performance de nos applicatifs digitaux. Ils permettent d’améliorer la pertinence, l’ergonomie et le fonctionnement des services proposés sur le site et l’application (par exemple, les pages les plus souvent consultées, les recherches les plus fréquentes dans le moteur de recherche du site, l'ergonomie de nouvelles versions de pages...).
        Notre outil d’analyse est Matomo.
    </p>
    <br>
    <table class="border w-100">
        <tr>
            <th class="border p-2">Nom du cookie</th>
            <th class="border p-2">Finalité</th>
            <th class="border p-2">Durée de conservation</th>
        </tr>
        <tr>
            <td class="border p-2">‘_pk_id’</td>
            <td class="border p-2">Identifiant de l’utilisateur</td>
            <td class="border p-2">13 mois</td>
        </tr>
        <tr>
            <td class="border p-2">‘_pk_ses’</td>
            <td class="border p-2">Données de visite</td>
            <td class="border p-2">30 min</td>
        </tr>
    </table>

    <br><br>
    <h3>3. Comment nous utilisons les cookies ?</h3>
    <p>
        Vous pouvez à tout moment refuser le dépôt de ces cookies et / ou modifier votre choix grâce à la case ci-dessous :
    </p>
    <p>
        @if (!isTracked) {
            <input (click)="track()" id="track" type="checkbox"> Vous n'êtes actuellement pas suivi(e). Cochez cette case
                    pour participer à l'amélioration du site
        } @else {
            <input (click)="untrack()" checked="checked" id="untrack" type="checkbox"> Vous êtes actuellement suivi(e).
                    Cochez cette case pour ne plus participer à l'amélioration du site.
        }
    </p>
</div>
