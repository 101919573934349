<div class="reship">
    <div class="ship-header">
        <div class="header-text">
            @if (!sponsorshipUser?.code) {
                <span class="bold">Devenir parrain</span>
            } @else {
                <span class="bold">Finaliser mon compte parrain</span>
            }
            <div class="back-text" routerLink="/home">
                <div class="fa-left">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i>
                </div>
                &nbsp;
                <p class="back-text">Retour</p>
            </div>
        </div>
        @if (!!marketplaceService.sponsorshipSettings) {
        <div class="ship-body">
            <div class="image-group position-relative">
                <img width="140"
                    height="106"
                    alt="sponsor"
                    class="w-100 h-100 object-fit-cover"
                    ngSrc="assets/img/img.png"
                    [priority]="true"
                    [placeholder]="placeholderImgBase64"
                    (load)="loadedImage($event)"
                    (error)="uiService.handleMissingImage($event)"
                />
                @if (isLoadedImg) {
                    <div class="image-text">
                        <div class="image-text-01">
                            {{marketplaceService.sponsorshipSettings ? marketplaceService.sponsorshipSettings.sponsorAmount : 0}}€
                        </div>
                        <div class="image-text-02">de plus dans votre cagnotte pour chaque parrainage...
                            et autant pour votre filleul !</div>
                    </div>
                }
            </div>

            <div class="ship-form ship-block">
                @if (sponsorForm && isLoadedImg) {
                    <form [formGroup]="sponsorForm" (ngSubmit)="submit()">
                        <div class="input-group">
                            <div class="input-01 ship-block">
                                <h6>Dites simplement à vos proches de venir chez nous de la part de :</h6>
                                <mat-form-field appearance="fill" class="ship_input input-margin">
                                    <mat-label>Lettres et chiffres acceptés</mat-label>
                                    <input formControlName="codeCustomised"
                                            (input)="removeSpace($event)"
                                            matInput/>
                                    <mat-error>
                                        Ce code parrain est déjà pris
                                    </mat-error>
                                </mat-form-field>
                                <p>Mbappé et Beyoncé sont déjà pris !</p>
                            </div>

                            <div class="input-02 ship-block">
                                <h6>
                                    Informations manquantes vous concernant :
                                </h6>
                                @if (!consumer.firstName) {
                                    <mat-form-field appearance="fill" class="ship_input input-margin">
                                        <mat-label>Prénom</mat-label>
                                        <input
                                                formControlName="firstName"
                                                matInput
                                                maxlength="30"
                                                type="text"/>
                                        <mat-error>
                                            La saisie du nom est obligatoire.
                                        </mat-error>
                                    </mat-form-field>
                                }
                                @if (!consumer.lastName) {
                                    <mat-form-field appearance="fill" class="ship_input input-margin">
                                        <mat-label>Nom</mat-label>
                                        <input
                                                formControlName="lastName"
                                                matInput
                                                maxlength="50"
                                                type="text">
                                        <mat-error>
                                            La saisie du nom est obligatoire.
                                        </mat-error>
                                    </mat-form-field>
                                }
                                @if (!consumer.email) {
                                    <mat-form-field appearance="fill" class="ship_input input-margin">
                                        <mat-label>Email</mat-label>
                                        <input
                                                formControlName="email"
                                                matInput
                                                type="email">
                                        <mat-error>
                                            Erreur
                                        </mat-error>
                                    </mat-form-field>
                                }
                                @if (!consumer.mobile) {
                                    <mat-form-field appearance="fill" class="ship_input input-margin">
                                        <mat-label>N° de portable</mat-label>
                                        <input
                                                formControlName="mobile"
                                                matInput
                                                type="tel"/>
                                        <mat-error>
                                            La saisie du numéro de téléphone est obligatoire.
                                        </mat-error>
                                    </mat-form-field>
                                }
                            </div>
                        </div>

                        <div class="ship-block">
                            <p>
                                Votre enregistrement sur la plateforme de parrainage vous permettra d’être régulièrement tenu
                                informé(e) par
                                email des nouvelles récompenses, résultats de vos actions et moyens de parrainer sans effort
                            </p>

                            <mat-checkbox class="checkbox" formControlName="allowCommunications">
                                <span class="check-box-text">
                                    Je souhaite recevoir les nouveautés relatives au programme de parrainage auquel je m’inscris
                                </span>
                            </mat-checkbox>

                            <p>
                                Les données collectées sont utilisées par votre banque pour vous permettre de parrainer vos amis
                                et vous
                                envoyer, le cas échéant, des informations susceptibles de vous intéresser.
                            </p>
                        </div>

                        <div class="form-button-div">
                            @if (!loading) {
                                <button
                                    [disabled]="!sponsorForm.valid"
                                    mat-raised-button
                                    class="btn-validate py-4 color-white px-5 text-nowrap"
                                    color="primary"
                                    type="submit">
                                    S’enregistrer
                                </button>
                            } @else {
                                <mat-spinner class="mx-auto my-3" [diameter]="32" />
                            }
                        </div>
                    </form>
                }
            </div>
        </div>
        }
    </div>
</div>
