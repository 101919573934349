<div [ngClass]="(isDesktop$ | async) ? 'pt-0' : (searchService.hideSearchEngine ? 'pt-3' : 'pt-84')">
    @if (displaySidenav) {
        <app-sidenav/>
    }
    <div id="tagsUlElement"
         [class.pt-5]="!showBanner"
         class="products-list container-fluid px-0 px-md-1 px-lg-3">
        <div class="position-relative">
            @if (scrollerService.showSpinnerForUp) {
                <app-loader class="top-loader" />
            }
            @if ((isDesktop$ | async) && showBanner) {
                <app-banner />
            }
        </div>
        @if (!scrollerService.isLowBalanceToLoadPartners) {
            @for (tag of loadedTags; track tag.id) {
                <div [id]="tag.id" class="py-4 app-tag-item">
                    <app-tag [tag]="tag"/>
                </div>
            }
        } @else {
            <div class="mt-5 d-flex justify-content-center" data-cy="low-balance-message">
                <h2 class="mt-5">Le montant de votre cagnotte est trop faible, aucune marque n'est disponible.</h2>
            </div>
        }

        <div class="d-flex justify-content-center py-5 mb-3" id="tagsUlSpacer">
            @if (uiService.loadingPartners && !scrollerService.showSpinnerForUp) {
                <app-loader class="m-auto"/>
            }
        </div>
    </div>

</div>
