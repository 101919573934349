import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService, PartnerOffer, Tag} from '@isifid/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map, Observable, of, take} from 'rxjs';
import {MarketplaceService} from '../../shared/services/marketplace.service';
import {LoaderService} from '../../shared/services/loader.service';
import {UiService} from '../../shared/services/ui.service';
import {PartnersService} from '../../shared/services/partners.service';
import {DialogService} from '../../shared/services/dialog.service';
import {ScrollerService} from '../../shared/services/scroller.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SearchService} from '../../shared/services/search.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    displaySidenav: boolean = false;
    tags: Array<Tag> = [];
    loadedTags: Array<Tag> = [];
    currentTag: Tag = new Tag();
    currentTagIndex: number = -1;
    showBanner: boolean = false;
    isDesktop$: Observable<boolean> = of(true);

    private destroyRef = inject(DestroyRef);

    constructor(
        public uiService: UiService,
        public readonly searchService: SearchService,
        public readonly marketplaceService: MarketplaceService,
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly dialogService: DialogService,
        private readonly loaderService: LoaderService,
        private readonly partnersService: PartnersService,
        private readonly router: Router,
        private readonly breakpointObserver: BreakpointObserver,
        public readonly scrollerService: ScrollerService
    ) {
        // Need to be auth or in demo mode to display the sidenav
        this.displaySidenav = this.authenticationService.isAuth.value || this.marketplaceService.demoMode;
        this.isDesktop$ = this.breakpointObserver.observe('(min-width: 992px)').pipe(map(s => s.matches));
        // Need to be auth or in demo mode to display the sidenav
        // Home is not more AuthGuarded since demo mode exist, so we manually check
        this.authenticationService.isAuth
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isAuth) => {
                if (!isAuth && !this.marketplaceService.demoMode) {
                    this.displaySidenav = false;
                    this.router.navigateByUrl('/logout').then();
                }
            });
        this.loaderService.start();
    }

    ngOnInit() {
        if (this.displaySidenav) {
            window.addEventListener('scroll', () => {
                this.scrollerService.loadPartnersWhenScrolling().subscribe();
            });
            this.scrollerService.init();
            this.marketplaceService.tags.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(s => this.tags = s);
            this.uiService.currentTagSubject
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(s => {
                    this.currentTag = s;
                    this.currentTagIndex = this.tags.findIndex(t => t.id === s.id);
                });
            this.uiService.tagsWithPartnersProductsSubject
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(s => {
                    this.loadedTags = s.map(t => t.tag);
                    this.showBanner = this.loadedTags.some(t => t.id === this.tags[0].id);
                });
    
            try {
                if (this.marketplaceService.getTags()?.length) {
                    // Try to select a tag by query param, otherwise we select the first by default
                    let paramTagId = Number(this.route.snapshot.queryParamMap.get('tagId'));
    
                    // Set tagId -1 as default one
                    if (paramTagId === 0) paramTagId = -1;
    
                    this.scrollerService.refreshPage(paramTagId).subscribe();
                    this.openDialogWithParams();
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    ngOnDestroy(): void {
        this.dialogService.closeAll();
    }

    // Open dialog according to the params when component is rendered
    private openDialogWithParams() {
        // Try to select a partnerId by query param
        const paramPartnerId = Number(this.route.snapshot.queryParamMap.get('partnerId'));
        const goToStep2 = !!this.route.snapshot.queryParamMap.get('offerId');
        if (paramPartnerId) {
            const partner = this.partnersService.getPartner(paramPartnerId);
            if (partner) {
                const partnerOffers: Array<PartnerOffer> = this.partnersService.getPartnerOffers(partner.id);
                this.dialogService.openOrderCardDialog(partner, partnerOffers, undefined, goToStep2);
            }
            return;
        }

        // Try to select a product by query param productId
        const paramProductId = Number(this.route.snapshot.queryParamMap.get('productId'));
        if (paramProductId) {
            // Open orderCard dialog for paramProductId only at the first time when products is loaded
            this.uiService.tagsWithPartnersProductsSubject
                .pipe(take(2), takeUntilDestroyed(this.destroyRef))
                .subscribe(() => {
                    const product = this.uiService.getProductsByTagId(this.currentTag.id).find(p => p.id === paramProductId);
                    if (product) this.dialogService.openOrderCardDialog(undefined, undefined, product, goToStep2);
                });
        }
    }
}

