import {Component, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {finalize} from 'rxjs';
import {AuthenticationService, FormValidators, MarketplaceSettings, MobileService, MsToolsMarketplaceService} from '@isifid/core';
import {UiService} from '../../../shared/services/ui.service';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {environment} from '../../../../environments/environment';
import {BootstrapService} from '../../../shared/services/bootstrap.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LogoutComponent {
    loading: boolean;
    settings?: MarketplaceSettings;
    accessForm: FormGroup;

    private typeMessage: string = '';

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly authenticationService: AuthenticationService,
        private readonly marketplaceService: MarketplaceService,
        private readonly bootstrapService: BootstrapService,
        private readonly msToolsMarketplaceService: MsToolsMarketplaceService,
        private readonly mobileService: MobileService,
        readonly uiService: UiService
    ) {
        this.loading = true;
        if (this.marketplaceService.demoMode) this.bootstrapService.destroyDemo();
        else if (this.authenticationService.isAuth.value) this.authenticationService.logout(false, false);

        this.initTemplate();
        this.accessForm = this.formBuilder.group({
            type: '',
            value: ['', [Validators.required, this.emailAndPhoneValidator()]],
            clientId: ''
        });
    }

    initTemplate(): void {
        this.marketplaceService.getSettingsByDomain()
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (settings) => this.settings = settings,
                error: () => {
                    console.error('error while getting client');
                    this.authenticationService.logout();
                }
            });
    }

    logo(): string {
        if (!this.settings?.logoUuid) return '';
        return `${environment.cdnUrl}/static/${this.settings.logoUuid}.png`;
    }

    submit(): void {
        this.loading = true;

        if (this.accessForm.get('type')?.value === 'mobile') {
            this.accessForm.get('content')?.setValue(this.mobileService.formatMobile(this.accessForm?.get('content')?.value));
        }
        this.accessForm.get('clientId')?.setValue(this.settings?.clientId);
        this.msToolsMarketplaceService.createAccess(this.accessForm?.value)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: () => this.snackBar.open('Le lien de connexion personnel a été envoyé à votre ' + this.typeMessage, 'X'),
                error: () => this.uiService.handleError()
            });
    }

    private emailAndPhoneValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value?.includes('@')) {
                this.accessForm?.get('type')?.setValue('mobile');
                this.typeMessage = 'numéro de téléphone';
                return this.formValidators.isFrenchMobilePhone(control as FormControl);
            } else {
                this.accessForm?.get('type')?.setValue('email');
                this.typeMessage = 'adresse e-mail';
                return this.formValidators.isEmail(control as FormControl);
            }
        };
    }
}
