<div class="history min-vp-100 py-5 px-2 px-lg-0 color-dark-grey">
    <div class="history-header">
        <div class="text-title bold">Historique</div>

        <div class="d-flex flex-row flex-wrap my-3">
            <div (click)="displayOrders = true" class="clickable my-2">
                <button
                    [class.bg-light-blue]="displayOrders"
                    [class.box-shadow-2]="!displayOrders"
                    class="btn btn-circle btn-lg color-original-blue text-center"
                    type="button"
                >
                    <img alt="gift icon" ngSrc="assets/icons/gift.png" width="30" height="21">
                </button>
                <span [class.bold]="displayOrders" class="px-3 text-large">Cartes cadeaux</span>
            </div>
            <div (click)="displayOrders = false" class="clickable my-2">
                <button
                    [class.bg-light-blue]="!displayOrders"
                    [class.box-shadow-2]="displayOrders"
                    class="btn btn-circle btn-lg color-original-blue text-center"
                    type="button"
                >
                    <i aria-hidden="true" class="far fa-star"></i>
                </button>
                <span [class.bold]="!displayOrders" class="px-3 text-large">Récompenses</span>
            </div>
        </div>

        <div class="close-button clickable" routerLink="/home">
            <i class="fas fa-times"></i>
        </div>
    </div>

    <!-- Orders ---------------------------------------------------------------------------------------------------- -->
    @if (displayOrders) {
        <div class="g-0">
            @if (orders) {
                <p class="text-normal">
                    @switch (orders.length) {
                        @case (0) {
                            Aucune carte cadeau commandée
                        }
                        @case (1) {
                            1 carte cadeau commandée
                        }
                        @default {
                            {{ orders.length }} cartes cadeaux commandées
                        }
                    }
                </p>
            }

            @for (order of orders; track order.id) {
                <div
                    class="order-card row flex-nowrap mx-0 px-2 justify-content-center mb-3 py-4 text-center"
                    (click)="openHistoryOrder(order)" aria-label="order-item" aria-hidden="true"
                >
                    <div class="col-3 col-sm-auto d-flex align-items-center">
                        <div class="partner-logo position-relative">
                            <img [ngSrc]="uiService.getPartnerLogoImg(getPartner(order.partnerId)) || 'error'"
                                 (error)="uiService.handleMissingImage($event)"
                                 alt="logo" class="circle object-fit-cover" [fill]="true">
                        </div>
                    </div>
                    <div class="col gap-2 ps-2 ps-sm-3 px-xl-5 text-left d-flex flex-column">
                        <div class="order-name">{{getPartner(order.partnerId)?.name | titlecase}}</div>
                        <div class="text-normal">
                            <a [href]="getPartner(order.partnerId)?.url"
                               target="_blank" class="text-decoration-underline site-link">Aller sur le site</a>
                        </div>
                        <div class="text-normal">
                            Valable jusqu'au {{dateService.computeDate(order.createdAt, 'dd/MM/yy', 30)}}
                        </div>
                    </div>
                    <div class="col-2 col-md-auto d-flex justify-content-center align-items-center amount pe-xl-5">
                        {{getOrderAmount(order)}}&nbsp;€
                    </div>
                    @if (getVoucherSecret(order.voucherId)) {
                        <div class="col d-none d-lg-flex flex-column justify-content-center gap-3">
                            <div class="m-0 border-grey border-radius-5 py-2 px-2 text-normal color-light-grey">
                                {{getVoucherCode(order.voucherId)}}
                            </div>
                            <button (click)="uiService.copyToClipboard(getVoucherCode(order.voucherId), $event.target)"
                                    class="btn-validate py-4 color-white text-nowrap"
                                    color="primary"
                                    mat-raised-button>
                                Copier le code
                            </button>
                        </div>
                        <div class="col d-none d-lg-flex flex-column justify-content-center gap-3">
                            <div class="m-0 border-grey border-radius-5 py-2 px-2 text-normal color-light-grey">
                                PIN: {{getVoucherSecret(order.voucherId)}}
                            </div>
                            <button (click)="uiService.copyToClipboard(getVoucherSecret(order.voucherId), $event.target)"
                                    class="btn-validate py-4 color-white text-nowrap"
                                    color="primary"
                                    mat-raised-button>
                                Copier le PIN
                            </button>
                        </div>
                    } @else {
                        <div class="col d-none d-lg-flex flex-column justify-content-center">
                            <div class="m-0 border-grey border-radius-5 py-2 px-2 text-normal color-light-grey">
                                {{getVoucherCode(order.voucherId)}}
                            </div>
                        </div>
                        <div class="col d-none d-lg-flex flex-column justify-content-center">
                            <button (click)="uiService.copyToClipboard(getVoucherCode(order.voucherId), $event.target)"
                                    class="btn-validate py-4 color-white text-nowrap"
                                    color="primary"
                                    mat-raised-button>
                                Copier le code
                            </button>
                        </div>
                    }
                    <div class="col d-none d-lg-flex flex-column justify-content-center">
                        <div (click)="sendOrderEmail($event.target, order.id)" class="receive-email clickable m-0 d-flex justify-content-center">
                            <i aria-hidden="true" class="fa-1x far fa-paper-plane my-auto"></i>
                            <span class="ps-3 text-nowrap">Recevoir par email</span>
                        </div>
                    </div>
                    <div class="col d-none d-xl-flex flex-column justify-content-center">
                        <button class="btn-validate py-4 color-white text-nowrap"
                                color="primary"
                                mat-raised-button
                                routerLink="/support"
                                type="button">
                            Un problème ?
                        </button>
                    </div>
                    <div class="col-auto d-none d-lg-flex d-xl-none flex-column justify-content-center px-0">
                        <button class="question-icon-button btn-validate color-white"
                                color="primary"
                                mat-raised-button
                                routerLink="/support"
                                title="Un problème ?"
                                type="button">
                            ?
                        </button>
                    </div>
                    <div class="col-2 d-flex d-lg-none align-items-center justify-content-end">
                        <i aria-hidden="true" class="fa fa-arrow-right"></i>
                    </div>
                </div>
            }
        </div>
    }

    <!-- Rewards --------------------------------------------------------------------------------------------------- -->
    @if (!displayOrders) {
        <div class="g-0">
            @if (rewards) {
                <div class="mb-1">
                    @if (rewards.length === 0) {<div>Aucune récompense</div>}
                    @else if (rewards.length === 1) {<div>1 récompense reçue</div>}
                    @else if (rewards.length > 1) {<div>{{ rewards.length }} récompenses reçues</div>}
                </div>
            }
            @for (reward of rewards; track reward.id) {
                <div (click)="openHistoryReward(reward)" aria-label="reward-item" aria-hidden="true"
                     class="row justify-content-center reward-card mx-0 mb-3 px-2 py-4 rounded text-center">
                    <div class="col d-flex align-items-center">
                        <div class="position-relative reward-logo h-100">
                            <img alt="logo" [fill]="true"
                                [ngSrc]="uiService.logoUrl"
                                (error)="uiService.handleMissingImage($event)">
                        </div>
                    </div>
                    <div class="col d-flex d-lg-none flex-column reward-title">
                        <div class="bold text-left">{{reward.label | titlecase}}</div>
                        @if (reward.expiresAt) {
                            <div class="text-left">
                                Valable jusqu'au {{reward.expiresAt | date: 'dd/MM/yyyy'}}
                            </div>
                        }
                    </div>
                    <div class="col reward-title my-auto text-left d-none d-lg-flex align-items-center">
                        <span class="bold">{{reward.label | titlecase}}</span>
                    </div>
                    <div class="col d-flex flex-column justify-content-center align-items-center">
                        <span class="reward-amount bold">{{ reward.amount }}&nbsp;€</span>
                    </div>
                    @if (reward.expiresAt) {
                        <div class="col text-left d-flex align-items-center d-none d-lg-flex">
                            <span class="text-nowrap">
                                Valable jusqu'au {{reward.expiresAt | date: 'dd/MM/yyyy'}}
                            </span>
                        </div>
                    }
                    <div class="col d-none d-xl-flex flex-column justify-content-center">
                        <button class="btn-validate py-4 color-white text-nowrap"
                                color="primary"
                                mat-raised-button
                                routerLink="/support"
                                type="button">
                            Un problème ?
                        </button>
                    </div>
                    <div class="col-auto d-none d-lg-flex d-xl-none flex-column justify-content-center px-0">
                        <button class="question-icon-button btn-validate color-white"
                                color="primary"
                                mat-raised-button
                                routerLink="/support"
                                title="Un problème ?"
                                type="button">
                            ?
                        </button>
                    </div>
                    <div class="col d-flex d-lg-none align-items-center justify-content-end g-0">
                        <i aria-hidden="true" class="fa fa-arrow-right"></i>
                    </div>
                </div>
            }
        </div>
    }
</div>
