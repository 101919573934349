import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Consumer, FormValidators, MsConsumersService, MsServicesSponsorshipService, SponsorshipUser} from '@isifid/core';
import {forkJoin, Observable} from 'rxjs';
import {ConsumersService} from 'src/app/shared/services/consumers.service';
import {MarketplaceService} from 'src/app/shared/services/marketplace.service';
import {UiService} from 'src/app/shared/services/ui.service';
import {Router} from '@angular/router';
import { placeholderImgBase64 } from 'src/app/shared/constants/placeholder-img';

@Component({
    selector: 'app-become-sponsor',
    templateUrl: './become-sponsor.component.html',
    styleUrls: ['become-sponsor.component.scss']
})
export class BecomeSponsorComponent {
    loading: boolean = false;
    sponsorForm: FormGroup;
    consumer: Consumer;
    sponsorshipUser: SponsorshipUser | undefined;
    isLoadedImg : boolean = false;
    placeholderImgBase64: string = placeholderImgBase64;

    constructor(
        public readonly marketplaceService: MarketplaceService,
        public readonly uiService: UiService,
        private readonly consumersService: ConsumersService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly msConsumersService: MsConsumersService,
        private readonly msSponsorshipService: MsServicesSponsorshipService,
        private readonly router: Router
    ) {
        // If already has a sponsorship user configured (allowCommunications != null), redirect to sponsorship
        if (this.consumersService.sponsorshipUser?.allowCommunications != null) this.router.navigate(['/account/sponsorship']).then();

        this.sponsorshipUser = this.consumersService.sponsorshipUser;
        this.consumer = this.consumersService.getConsumer();
        this.sponsorForm = this.formBuilder.group({
            codeCustomised: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]+$')]],
            allowCommunications: !!marketplaceService.sponsorshipSettings?.optOutEnabled,
            firstName: [{value: '', disabled: !!this.consumer?.firstName}, [Validators.required, this.formValidators.isName]],
            lastName: [{value: '', disabled: !!this.consumer?.lastName}, [Validators.required, this.formValidators.isName]],
            email: [{value: '', disabled: !!this.consumer?.email}, [Validators.required, Validators.email]],
            mobile: [{value: '', disabled: !!this.consumer?.mobile}, [Validators.required, this.formValidators.isFrenchMobilePhone]]
        });
    }

    submit(): void {
        if (this.sponsorForm.invalid) return;

        this.loading = true;
        const obs: Array<Observable<any>> = [];
        const sponsorshipUser = {
            ...this.consumersService.sponsorshipUser,
            source: 'marketplace',
            medium: 'conversion'
        };
        sponsorshipUser.consumerId = this.consumer.id;
        sponsorshipUser.clientId = this.consumer.clientId;
        sponsorshipUser.allowCommunications = this.sponsorForm.get('allowCommunications')?.value;
        sponsorshipUser.codeCustomised = this.sponsorForm.get('codeCustomised')?.value;
        // Check if the code is not already used
        this.msSponsorshipService.getSponsorshipUserByCode(sponsorshipUser.codeCustomised).subscribe({
            // If already used, set error
            next: () => {
                this.sponsorForm.get('codeCustomised')?.setErrors({alreadyUsed: true});
                this.loading = false;
            },
            error: (e) => {
                // If not found (404), update the consumer and create the sponsorshipUser
                if (e.status === 404) {
                    if (!this.consumer?.firstName || !this.consumer?.lastName || !this.consumer?.email || !this.consumer?.mobile) {
                        const data = this.sponsorForm?.value;
                        delete data.codeCustomised;
                        delete data.allowCommunications;

                        obs.push(this.msConsumersService.updateConsumer(this.consumer?.id, data));
                    }
                    obs.push(this.msServicesSponsorshipService.createSponsorshipUser(sponsorshipUser));

                    forkJoin(obs)
                        .subscribe({
                            next: () => {
                                this.router.navigate(['/account/sponsorship']).then()
                            },
                            error: () => {
                                this.loading = false;
                                this.uiService.handleError();
                            }
                        });
                } else console.error('Error while checking if the custom code is already used');
            }
        });
    }

    removeSpace(event: any): void {
        this.sponsorForm.get('codeCustomised')?.setValue((this.sponsorForm.get('codeCustomised')?.value??'' + event.data).replace(/\s/g, ''));
    }

    loadedImage(event: any): void {
        this.isLoadedImg = event.isTrusted;
    }
}
