import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeComponent} from './home.component';
import {SharedModule} from '../../shared/shared.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SidenavComponent} from './sidenav/sidenav.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {TagComponent} from './tag/tag.component';
import {BannerComponent} from './banner/banner.component';
import {SidenavTagsComponent} from './sidenav/tags/tags.component';


@NgModule({
    declarations: [
        HomeComponent,
        SidenavComponent,
        TagComponent,
        BannerComponent,
        SidenavTagsComponent
    ],
    exports: [
        HomeComponent
    ],
    imports: [
        CommonModule,
        // Angular material
        MatButtonModule,
        SharedModule,
        ScrollingModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatToolbarModule
    ]
})
export class HomeModule {
}
