<h1 class="text-title text-center my-5">Conditions générales d’utilisation</h1>

<div class="container px-2 px-lg-5 text-justify text-normal">
    Vous avez reçu un montant, exprimé en euros, vous permettant de vous procurer des cartes cadeau, proposées par des
    sites de e-commerce partenaires.
    <br><br>
    Le montant qui vous a été attribué a une validité de 6 mois à compter de sa mise à disposition. A partir de la date
    à laquelle vous commandez une carte cadeau et le code d’achat qui y est attaché, la durée de validité du code qui
    vous est délivré est de 1 mois.
    <br><br>
    Pour obtenir une carte cadeau, sélectionnez votre enseigne puis cliquez sur le bouton « commander un code de x € »
    et confirmez cette commande. Dans le cas où nous ne disposerions pas de votre adresse e-mail, celle-ci vous sera
    demandée lors de la validation de votre commande.<br>
    Dès que vous avez commandé un code, celui-ci s’affiche sous la forme d’un numéro unique. Ce code d'achat vous est
    également envoyé par mail.
    <br><br>
    La valeur du code commandé est immédiatement décompté de votre montant disponible.
    <br><br>
    Dès que vous avez commandé la carte cadeau d’un site e-commerce, ce site disparaît de votre galerie car vous ne
    pouvez utiliser qu’une seule carte cadeau par site partenaire.
    <br><br>
    L'utilisation du code d'achat chez le site partenaire intervient lors du règlement de votre commande. Une zone est
    prévue pour vous permettre d'inscrire votre code. Le montant de votre code sera déduit de votre commande. Si le
    montant de votre commande est supérieur au montant de votre code, il vous sera demandé de compléter le règlement par
    carte bancaire. Si le montant de votre commande est inférieur ou égal au montant de votre code, ce dernier couvrira
    intégralement votre achat (hors frais de port); le code est intégralement consommé même si sa valeur dépasse celle
    de votre commande. Toutefois, certaines enseignes imposent que le montant de la commande ne soit pas inférieur ou
    égal à la valeur du code.
    <br><br>
    La délivrance d’un code ne peut donner lieu à aucune annulation ni remboursement ni réaffectation de crédit, sauf en
    cas de dysfonctionnement avéré, après vérification auprès de l’enseigne concernée.
    <br><br>
    Aucun minimum d’achat n’est exigé, à l’exception de certains codes d’achat signalés par un astérisque. Ces
    exceptions imposent un minimum d’achat égal à deux fois la valeur du bon (hors frais de port).
    <br><br>
    Les codes d’achat ne sont pas nominatifs et sont donc cessibles. Ils ne sont pas cumulables avec d'autres offres
    commerciales en cours sur les sites référencés. Les codes d’achat non utilisés ne sont pas remboursables. Les codes
    d’achat ne sont pas échangeables contre leur équivalent monétaire.
    <br><br>
    <br><br>
    <div class="text-center mb-5">
        <button (click)="return()"
                class="btn-validate py-1 color-white"
                color="primary" mat-raised-button
                type="button">
            Retour
        </button>
    </div>
</div>
