import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Consumer, LabelledReward} from '@isifid/core';
import {ConsumersService} from '../../../shared/services/consumers.service';
import {UiService} from '../../../shared/services/ui.service';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html'
})
export class SupportComponent {
    emailSupport: string = '';
    loading: boolean = false;
    rewards: Array<LabelledReward> = [];
    supportForm: UntypedFormGroup | undefined;
    private consumer: Consumer;

    constructor(
        private readonly consumersService: ConsumersService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly marketplaceService: MarketplaceService,
        private readonly snackBar: MatSnackBar,
        private readonly uiService: UiService
    ) {
        this.consumer = this.consumersService.getConsumer();
        this.rewards = this.consumersService.rewards;
        this.emailSupport = this.marketplaceService.getSettings().emailSenderAddress;
        this.initForm();
    }

    submit() {
        if (!this.supportForm || this.supportForm?.invalid) return;
        this.sendMailSupport(this.supportForm.getRawValue());
    }

    private initForm() {
        this.supportForm = this.formBuilder.group({
            email: [this.consumer.email, [Validators.required, Validators.email]],
            message: ['', [Validators.required, Validators.minLength(7)]],
            name: `${this.consumer.firstName} ${this.consumer.lastName}`,
            subject: [''],
            reward: ['']
        });
    }

    private sendMailSupport(support: any): void {
        this.loading = true;
        support.message += ' Message envoyé par : ' + support.name + ' email : ' + support.email;
        this.marketplaceService.sendEmailSupport(support)
            .subscribe({
                next: () => {
                    this.snackBar.open('Email envoyé avec succès', 'X');
                    this.initForm();
                },
                error: () => this.uiService.handleError(),
                complete: () => this.loading = false
            });
    }
}
