<div id="tags">
    <div class="d-none d-lg-block categories-title py-4 mb-2 ps-3 bold">
        Catégories
    </div>

    @for (tag of tags; track tag.id) {
        <div class="h6 mb-0 " [id]="'menu' + tag.id">
            <div (click)="clickCategory(tag)"
                 [ngClass]="{'selected bold': currentTag.id === tag.id}"
                 class="d-block d-lg-flex clickable flex-row">
                <button [class.bg-light-blue]="currentTag.id === tag.id" [class.box-shadow-2]="currentTag.id !== tag.id"
                        class="d-none d-lg-flex btn btn-circle color-original-blue"
                        type="button">
                    <img [ngSrc]="getTagImg(tag.configuration)"
                         (error)="uiService.handleMissingImage($event)"
                         alt="tag"
                         class="align-baseline" height="30" width="30">
                </button>
                <p class="tag-name d-none d-lg-block px-3 my-auto">{{ tag.name }}</p>
                <div class="tag-name d-block d-lg-none text-nowrap" [class.color-original-blue]="currentTag.id === tag.id">{{ tag.name }}</div>
            </div>
        </div>
    }
</div>
