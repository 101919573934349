import {Component, OnInit} from '@angular/core';
import {BootstrapService} from './shared/services/bootstrap.service';
import {AuthenticationService} from '@isifid/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isAuth: boolean;
    isInitialised: boolean;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly bootstrapService: BootstrapService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly router: Router
    ) {
        this.isAuth = false;
        this.isInitialised = false;
    }

    get isAppReady() {
        if (this.isInitialised) return true;
        return !this.isAuth;
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    ngOnInit() {
        this.authenticationService.isAuth.subscribe({next: (isAuth) => this.isAuth = isAuth});
        this.bootstrapService.isInitialised.subscribe({next: (isInitialised) => this.isInitialised = isInitialised});
    }
}
