import {Component} from '@angular/core';
import {TrackingService} from '@isifid/core';
import {ActivatedRoute} from '@angular/router';
import {TrackingMarketplaceService} from '../../../shared/services/tracking-marketplace.service';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html'
})
export class CookiesComponent {
    isTracked: boolean = true;

    constructor(
        private readonly trackingService: TrackingService,
        private readonly trackingMarketplaceService: TrackingMarketplaceService,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.trackingService.trackCurrentPage(this.activatedRoute.snapshot, this.trackingMarketplaceService.getClientTrackerId()).subscribe();
        this.isTracked = trackingMarketplaceService.isTracked();
    }

    track(): void {
        this.trackingMarketplaceService.startTracking();
        this.isTracked = true;
    }

    untrack(): void {
        this.trackingMarketplaceService.stopTracking();
        this.isTracked = false;
    }
}
