import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LogoutComponent} from './logout/logout.component';
import {SupportComponent} from './support/support.component';
import {PersonalInfosComponent} from './personal-infos/personal-infos.component';
import {authGuardChildFn, authGuardFn, trackGuardChildFn, trackGuardFn} from '@isifid/core';
import {HistoryComponent} from './history/history.component';
import {LoginComponent} from './login/login.component';
import {AuthComponent} from './auth/auth.component';
import {BecomeSponsorComponent} from './become-sponsor/become-sponsor.component';
import {SponsorshipComponent} from './sponsorship/sponsorship.component';
import {LayoutNormalComponent} from 'src/app/shared/components/layouts/layout-normal/layout-normal.component';
import { LayoutNoFooterComponent } from 'src/app/shared/components/layouts/layout-no-footer/layout-no-footer.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutNormalComponent,
        children: [
            {
                path: 'logout',
                canActivate: [trackGuardFn],
                component: LogoutComponent
            },
            {
                path: 'support',
                canActivate: [authGuardFn, trackGuardFn],
                component: SupportComponent
            },
            {
                path: 'demo',
                component: AuthComponent
            },
            {
                path: 'account',
                canActivateChild: [authGuardChildFn, trackGuardChildFn],
                children: [
                    {
                        path: '',
                        component: PersonalInfosComponent
                    },
                    {
                        path: 'history',
                        component: HistoryComponent
                    },
                    {
                        path: 'sponsorship',
                        component: SponsorshipComponent
                    },
                    {
                        path: 'become-sponsor',
                        component: BecomeSponsorComponent
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: LayoutNoFooterComponent,
        children: [
            {
                path: 'auth',
                component: AuthComponent
            },
            {
                path: 'log/r32/:purlToken',
                component: AuthComponent
            },
            {
                path: 'email',
                component: AuthComponent
            },
            
            {
                path: 'login',
                canActivate: [trackGuardFn],
                component: LoginComponent
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule {
}
