import {Injectable, PLATFORM_ID, inject} from '@angular/core';
import {CookiesService} from '@isifid/core';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../../environments/environment';

declare let _paq: any;

@Injectable({
    providedIn: 'root'
})
export class TrackingMarketplaceService {
    private cookiesService = inject(CookiesService);
    private platformId = inject(PLATFORM_ID);

    getClientTrackerId(): number | undefined {
        // Only for real browser
        if (isPlatformBrowser(this.platformId)) return undefined;
        return environment.matomoSiteId;
    }

    isTracked(): boolean {
        // Only for real browser
        if (!isPlatformBrowser(this.platformId)) return false;

        return !this.cookiesService.getCookie('mtm_consent_removed');
    }

    stopTracking() {
        // Check _paq to avoid error occurs when building for ssr production
        if (typeof _paq !== 'undefined') {
            _paq.push(['optUserOut']);
        }
    }

    startTracking() {
        // Check _paq to avoid error occurs when building for ssr production
        if (typeof _paq !== 'undefined') {
            _paq.push(['forgetUserOptOut']);
        }
    }
}
