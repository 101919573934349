import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidators, MobileService, MsConsumersService, ConfigurationService} from '@isifid/core';
import {ConsumersService} from '../../../shared/services/consumers.service';
import {UiService} from '../../../shared/services/ui.service';
import {BootstrapService} from '../../../shared/services/bootstrap.service';
import {MarketplaceService} from 'src/app/shared/services/marketplace.service';

@Component({
    selector: 'app-personal-infos',
    templateUrl: './personal-infos.component.html',
    styleUrls: ['./personal-infos.component.scss']
})
export class PersonalInfosComponent implements OnInit {
    loading: boolean = true;
    personalInfosForm: FormGroup | undefined;
    checkedMobileCold: boolean = false;

    constructor(
        private readonly bootstrapService: BootstrapService,
        private readonly consumersService: ConsumersService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly mobileService: MobileService,
        private readonly msConsumersService: MsConsumersService,
        private readonly uiService: UiService,
        private readonly marketplaceService: MarketplaceService,
        private readonly configurationService: ConfigurationService
    ) {
    }

    ngOnInit() {
        this.bootstrapService.isInitialised.subscribe((isInitialised) => {
            if (isInitialised) {
                this.initForm();
                this.loading = false;
                this.checkedMobileCold = this.configurationService.getValueByKey(this.marketplaceService.client.configuration, 'marketplaceMobileColdCallNotice');
            }
        });
    }

    update() {
        if (!this.personalInfosForm || this.personalInfosForm.invalid || !this.personalInfosForm.touched) return;
        this.loading = true;
        if (this.personalInfosForm.get('mobile')?.value) {
            this.personalInfosForm.get('mobile')?.setValue(this.mobileService.formatMobile(this.personalInfosForm.get('mobile')?.value));
        }
        this.msConsumersService.updateConsumer(this.consumersService.consumer?.id, this.personalInfosForm.getRawValue())
            .subscribe({
                next: (consumer) => {
                    this.consumersService.consumer = consumer;
                    this.initForm();
                    this.loading = false;
                }, error: () => this.uiService.handleError()});
    }

    private initForm() {
        let gender = '0';
        if (this.consumersService.consumer?.gender) gender = this.consumersService.consumer.gender.toString();

        this.personalInfosForm = this.formBuilder.group({
            firstName: [this.consumersService.consumer?.firstName, [Validators.required, this.formValidators.isName]],
            lastName: [this.consumersService.consumer?.lastName, [Validators.required, this.formValidators.isName]],
            email: [this.consumersService.consumer?.email, [Validators.required, Validators.email]],
            mobile: [this.consumersService.consumer?.mobile, this.formValidators.isFrenchMobilePhone],
            gender: [gender, Validators.required]
        });
        // If email is set, it's not possible to change it
        if (this.consumersService.consumer?.email) this.personalInfosForm.controls.email.disable();
    }
}
