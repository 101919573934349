import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {Partner, PartnerOffer, Product, Tag, TrackingService} from '@isifid/core';
import {environment} from '../../../../environments/environment';
import {UiService} from '../../../shared/services/ui.service';
import {DialogService} from '../../../shared/services/dialog.service';
import {PartnersService} from '../../../shared/services/partners.service';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnChanges {
    env = environment;
    partners: Array<Partner> = [];
    products: Array<Product> = [];
    scrollValuePartners: number = 0;
    scrollValueProducts: number = 0;
    partnersSwipeChevronHideLeft: boolean = true;
    productsSwipeChevronHideLeft: boolean = true;

    private subscription: Subscription = new Subscription();

    @Input() tag: Tag | undefined;
    @ViewChild('selectedPartnersContent') public selectedPartnersContent?: ElementRef<any>;
    @ViewChild('selectedProductContent') public selectedProductContent?: ElementRef<any>;

    constructor(
        public uiService: UiService,
        public readonly dialogService: DialogService,
        public readonly partnersService: PartnersService,
        public readonly trackingService: TrackingService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tag?.currentValue.id) {
            this.partners = this.uiService.getPartnersByTagId(changes.tag.currentValue.id);
            this.products = this.uiService.getProductsByTagId(changes.tag.currentValue.id);
            timer(10).subscribe(() => {
                this.scrolledIndexPartnersChange();
                this.scrolledIndexProductsChange();
            });
        }
    }

    swipeProducts(direction: number) {
        let swipeValue: number = this.selectedProductContent?.nativeElement.scrollLeft;
        if (direction === 1) swipeValue += this.selectedProductContent?.nativeElement.clientWidth - 250;
        else swipeValue -= this.selectedProductContent?.nativeElement.clientWidth + 250;

        this.selectedProductContent?.nativeElement.scrollTo({left: swipeValue, behavior: 'smooth'});
    }

    swipePartners(direction: number) {
        let swipeValue: number = this.selectedPartnersContent?.nativeElement.scrollLeft;
        if (direction === 1) swipeValue += this.selectedPartnersContent?.nativeElement.clientWidth - 250;
        else swipeValue -= this.selectedPartnersContent?.nativeElement.clientWidth + 250;

        this.selectedPartnersContent?.nativeElement.scrollTo({left: swipeValue, behavior: 'smooth'});
    }

    scrolledIndexProductsChange() {
        // Hide left chevron if scrollLeft = 0 or if selectedProductContent is not visible
        this.productsSwipeChevronHideLeft = Number(this.selectedProductContent?.nativeElement.scrollLeft.toFixed(1)) === 0;

        // Get value for mat-progress-bar
        this.scrollValueProducts = Number(((this.selectedProductContent?.nativeElement?.scrollLeft +
                this.selectedProductContent?.nativeElement?.offsetWidth) /
            this.selectedProductContent?.nativeElement?.scrollWidth * 100).toFixed(1));
    }

    scrolledIndexPartnersChange() {
        // Hide left chevron if scrollLeft = 0 or if selectedPartnersContent is not visible
        this.partnersSwipeChevronHideLeft = Number(this.selectedPartnersContent?.nativeElement.scrollLeft.toFixed(1)) === 0;

        // Get value for mat-progress-bar
        this.scrollValuePartners = Number(((this.selectedPartnersContent?.nativeElement?.scrollLeft +
                this.selectedPartnersContent?.nativeElement?.offsetWidth) /
            this.selectedPartnersContent?.nativeElement?.scrollWidth * 100).toFixed(1));
    }

    openOrderCardDialog(partner: Partner, goToStep2?: boolean) {
        const partnerOffers: Array<PartnerOffer> = this.partnersService.getPartnerOffers(partner.id);
        this.dialogService.openOrderCardDialog(partner, partnerOffers, undefined, goToStep2);
    }

    unsubscribe(): void {
        this.subscription.unsubscribe();
    }


    openProductCard(product: Product, event: MouseEvent) {
        const triggerElement: HTMLElement = new ElementRef(event.currentTarget).nativeElement as unknown as HTMLElement;
        this.subscription = timer(500).subscribe(() => {
            // Return if smartphone or tablet with touch screen or if height is too small
            if (window.ontouchstart || screen.width <= 992 || window.innerHeight <= 705) return;
            else this.dialogService.openProductCardDialog(product, triggerElement);
        });
    }

    openGiftCard(partner: Partner, event: MouseEvent) {
        const triggerElement: HTMLElement = new ElementRef(event.currentTarget).nativeElement as unknown as HTMLElement;
        this.subscription = timer(500).subscribe(() => {
            // Return if smartphone or tablet with touch screen or if height is too small
            if (window.ontouchstart || screen.width <= 992 || window.innerHeight <= 705) return;
            else this.dialogService.openGiftCardDialog(partner, this.partnersService.getPartnerOffers(partner.id), triggerElement);
        });
    }

    clickGiftCard(partner: Partner) {
        this.unsubscribe();
        // Open orderCard dialog and track event
        this.openOrderCardDialog(partner);
        this.trackingService.trackEvent('partner', 'select partner ' + partner.name, 'select-partner ' + partner.id).subscribe();
    }

    clickProductCard(product: Product) {
        this.unsubscribe();
        // Open orderCard dialog and track event
        this.dialogService.openOrderCardDialog(undefined, undefined, product);
        this.trackingService.trackEvent('product', 'select product partnerId ' + product.partnerId, 'select-product ' + product.label).subscribe();
    }
}
