<div class="container d-flex align-items-center justify-content-center text-center">
    @if (loading) {
        <div>
            <h1>Déconnexion en cours</h1>
            <div class="fa-3x">
                <em class="fas fa-sync fa-spin"></em>
            </div>
        </div>
    } @else {
        <div class="d-flex flex-column gap-2 px-2">
            @if (logo()) {
                <app-img [src]="logo()" cssClasses="mb-4 logo-logout" alt="logo" />
            }
            @if (settings && settings.clientId >= 0) {
                <form class="d-flex flex-column gap-4 align-items-center" (ngSubmit)="submit()" [formGroup]="accessForm">
                    <h4 class="mb-2">Connexion à votre espace de récompenses</h4>
                    <h6 class="content">Merci de renseigner votre numéro de téléphone ou adresse email afin de recevoir votre lien personnel de connexion.</h6>
                    <div class="d-flex flex-column gap-2 w-100 form-body">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Email ou téléphone</mat-label>
                            <input class="w-100" formControlName="value" matInput />
                        </mat-form-field>

                        <button [disabled]="!accessForm.valid"
                            class="btn-validate w-100 rounded-0 py-4"
                            mat-raised-button
                            type="submit">
                            Recevoir mon lien
                        </button>
                    </div>
                </form>
            }
        </div>
    }
</div>
