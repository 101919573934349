import {Injectable} from '@angular/core';
import {UiService} from './ui.service';
import {debounceTime, delay, finalize, Observable, of, switchMap, tap, timer} from 'rxjs';
import {Tag} from '@isifid/core';
import {MarketplaceService} from './marketplace.service';
import {PartnersService} from './partners.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DialogService} from './dialog.service';
import {SearchService} from './search.service';

@Injectable({providedIn: 'root'})
// Service used for Home page scrolling
export class ScrollerService {
    public showSpinnerForUp: boolean = false;
    private tags: Tag[] = [];
    private loadedTags: Tag[] = [];
    private currentTagId!: number;
    private currentTagIndex: number = -1;
    private currentScrollPosition: number = 0;
    private isScrollingDown: boolean = true;
    private isInitialLoading: boolean = false;

    constructor(
        private readonly uiService: UiService,
        private readonly marketplaceService: MarketplaceService,
        private readonly partnersService: PartnersService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly dialogService: DialogService,
        private readonly searchService: SearchService
    ) {
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    get isLowBalanceToLoadPartners(): boolean {
        return (this.loadedTags.length === 0 && !this.uiService.loadingPartners) || !this.partnersService.getPartnersList()?.length;
    }

    public init(): void {
        this.marketplaceService.tags.subscribe((tags: Array<Tag>) => this.tags = tags);
        this.uiService.tagsWithPartnersProductsSubject.subscribe(s => this.loadedTags = s.map(t => t.tag));
        this.uiService.currentTagSubject
            .pipe(debounceTime(300))
            .subscribe((tag: Tag) => {
                if (tag?.id !== this.currentTagId) {
                    this.currentTagId = tag.id;
                    this.currentTagIndex = this.tags.findIndex(s => s.id === tag.id);
                    this.scrollCorrespondingTagMenuItem(tag.id);
                }
            });
    };

    public scrollCorrespondingTagMenuItem(tagId: number): void {
        document?.getElementById('menu' + tagId)?.scrollIntoView({block: 'nearest', inline: 'center'});
    }

    public refreshPage(tagId: number): Observable<null> {
        const tagsUl = document.getElementById('tagsUlElement') as HTMLUListElement;
        if (!tagsUl || this.currentTagId === tagId) return of(null);

        this.isInitialLoading = true;
        this.uiService.clearTagsWithPartnersProducts();
        this.setSpacerHeight(window.innerHeight - 240);

        return this.uiService.selectTag(tagId, true)
            .pipe(
                delay(50),
                tap(() => {
                    if (this.searchService.hideSearchEngine) {
                        window.scrollTo({
                            top: (document.getElementById(`${tagId}`)?.offsetTop ?? 0) -
                                (this.isDesktop ? 112 : 129),
                            behavior: 'instant'
                        });
                    } else {
                        window.scrollTo({
                            top: (document.getElementById(`${tagId}`)?.offsetTop ?? 0) -
                                (this.isDesktop ? 112 : 204),
                            behavior: 'instant'
                        });
                    }
                }),
                switchMap(() => this.loadPartners()),
                finalize(() => this.isInitialLoading = false)
            );
    }

    public loadPartnersWhenScrolling(): Observable<null> {
        if (this.dialogService.productCardDialogRef) {
            timer(300).subscribe(() => this.dialogService.closeProductCardDialog());
        }
        if (this.dialogService.giftCardDialogRef) {
            timer(300).subscribe(() => this.dialogService.closeGiftCardDialog());
        }
        this.isScrollingDown = window.scrollY > this.currentScrollPosition;
        // If partners are loading, return null
        if (this.uiService.loadingPartners || this.isInitialLoading || this.isLowBalanceToLoadPartners) return of(null);

        const observer = this.isScrollingDown ? this.loadPartnersWhenScrollingDown() : this.loadPartnersWhenScrollingUp();
        return observer.pipe(tap(() => this.selectCorrespondingTag()));
    }

    public scrollToPartnersSection(tagId: number): void {
        const tags = (this.tags[0].id === tagId || this.tags[this.tags.length - 1].id === tagId) ?
            this.loadedTags :
            this.loadedTags.slice(1, this.loadedTags.length - 1);

        if (tags.find(s => s.id === tagId)) {
            if (this.searchService.hideSearchEngine) {
                window.scrollTo({
                    top: (document.getElementById(`${tagId}`)?.offsetTop ?? 0) - (this.isDesktop ? 112 : 129),
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo({
                    top: (document.getElementById(`${tagId}`)?.offsetTop ?? 0) - (this.isDesktop ? 112 : 204),
                    behavior: 'smooth'
                });
            }
        } else {
            this.refreshPage(tagId).subscribe();
        }
    }

    private loadPartners(): Observable<null> {
        if (!this.uiService.loadingPartners && this.isSpacerInViewport()) {
            if (this.searchService.hideSearchEngine) {
                this.setSpacerHeight(this.getSpacerHeight() - this.getLastPartnersHeight() -
                    (this.isDesktop ? 112 : 129));
            } else {
                this.setSpacerHeight(this.getSpacerHeight() - this.getLastPartnersHeight() -
                    (this.isDesktop ? 112 : 204));
            }
            const nextTag = this.tags.find((s, i) => i > this.currentTagIndex && this.loadedTags.every(t => s.id !== t.id));
            return nextTag ?
                this.uiService.getPartnersProducts(nextTag).pipe(switchMap(() => this.loadPartners())) :
                of(null);
        } else {
            this.setSpacerHeight(0);
            return of(null);
        }
    }

    private loadPartnersWhenScrollingDown(): Observable<null> {
        if (this.loadedTags?.[this.loadedTags.length - 2]?.id === this.uiService.currentTagSubject.value.id) {
            const nextTag = this.tags[this.tags.findIndex(s => s.id === this.loadedTags[this.loadedTags.length - 1]?.id) + 1];
            return nextTag ? this.uiService.getPartnersProducts(nextTag) : of(null);
        } else {
            return of(null);
        }
    }

    private loadPartnersWhenScrollingUp(): Observable<null> {
        // Get previous tag to be loaded
        const previousTagIndex = this.tags.findIndex(s => s.id === this.loadedTags[0].id) - 1;
        const previousTag = this.tags[previousTagIndex];

        // If the previous tag was already loaded, return.
        if (this.uiService.currentTagSubject.value.id !== this.loadedTags[1].id || !previousTag) return of(null);

        this.showSpinnerForUp = true;

        return this.uiService.getPartnersProducts(previousTag, false, true)
            .pipe(
                delay(50),
                tap(() => {
                    this.showSpinnerForUp = false;
                    if (!this.isScrollingDown || previousTagIndex < 2) this.scrollToCorrespondingTagSection();
                })
            );
    }

    private selectCorrespondingTag(): void {
        this.currentScrollPosition = window.scrollY;
        const elements = document.getElementById('tagsUlElement')?.children;
        // Don't scroll if it's first tag and scroll direction is up
        if (!this.showSpinnerForUp && elements && !(!this.isScrollingDown && this.tags.findIndex(s => s.id === this.currentTagId) === 0)) {
            for (const element of Array.from(elements)) {
                if (
                    this.loadedTags.find(s => s.id === parseInt(element.id)) &&
                    this.isFirstElementInViewport(element as HTMLElement)
                ) {
                    this.uiService.selectTag(parseInt(element.id)).subscribe();
                    break;
                }
            }
        }
    }

    private isSpacerInViewport() {
        const rect = document.getElementById('tagsUlSpacer')?.getBoundingClientRect();
        if (!rect) {
            return false;
        } else if (this.searchService.hideSearchEngine) {
            return (rect.top ?? 0) < window.innerHeight - (this.isDesktop ? 112 : 129);
        } else {
            return (rect.top ?? 0) < window.innerHeight - (this.isDesktop ? 112 : 204);
        }

    }

    // Check if element is first which is in viewport
    private isFirstElementInViewport(el: HTMLElement) {
        // If the scroll direction is down, the top of element should be shown.
        // If the scroll direction is up, the 1 of 3 of the element's height should be shown.
        return this.isScrollingDown ?
            el.getBoundingClientRect().top >= 0 :
            el.getBoundingClientRect().bottom > (el.offsetHeight);
    }

    // Get height of last partners & products
    private getLastPartnersHeight(): number {
        const tagsUl = document.getElementById('tagsUlElement') as HTMLUListElement;
        return tagsUl ? tagsUl.children[tagsUl.children.length - 2].clientHeight : 0;
    }

    // Get height of spacer where a spinner is shown for scrolling down
    private getSpacerHeight(): number {
        return document.getElementById('tagsUlSpacer')?.clientHeight ?? 0;
    }

    // Set height of spacer where a spinner is shown for scrolling down
    private setSpacerHeight(value: number): void {
        (document.getElementById('tagsUlSpacer') as HTMLElement).style.height = `${value}px`;
    }

    private scrollToCorrespondingTagSection(): void {
        document?.getElementById(`${this.currentTagId}`)?.scrollIntoView();
        window.scrollTo({
            top: (document?.getElementById(`${this.currentTagId}`)?.offsetTop ?? 0) - this.uiService.currentTagPositionY,
            behavior: 'instant'
        });
    }
}
