import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { TrackGuard } from '@isifid/core';
import {NotFoundComponentComponent} from './shared/components/not-found/not-found.component';
import {LayoutNoFooterComponent} from './shared/components/layouts/layout-no-footer/layout-no-footer.component';
import {LayoutNormalComponent} from './shared/components/layouts/layout-normal/layout-normal.component';
import { HomeComponent } from './modules/home/home.component';

const preRoutes: Routes = [
    {
        path: '',
        component: LayoutNormalComponent,
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'legal',
                redirectTo: 'legal/terms',
                pathMatch: 'full'
            },
            {
                path: 'home',
                canActivate: [TrackGuard],
                component: HomeComponent
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(preRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

const postRoutes: Routes = [
    {
        path: '',
        component: LayoutNoFooterComponent,
        children: [
            {
                path: 'catalogue',
                loadChildren: () => import('./modules/catalogue/catalogue.module').then(m => m.CatalogueModule)
            },
            {
                path: '404',
                component: NotFoundComponentComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(postRoutes)],
    exports: [RouterModule]
})
export class AppPostRoutingModule {
}
