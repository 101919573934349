import {Component, OnInit} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {ConsumersService} from '../../../shared/services/consumers.service';
import {DialogService} from '../../../shared/services/dialog.service';
import {MarketplaceService} from '../../../shared/services/marketplace.service';
import {UiService} from '../../../shared/services/ui.service';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    expiresAt?: string;
    showMobileBanner$: Observable<boolean> = of(false);

    constructor(
        public readonly consumersService: ConsumersService,
        public readonly dialogService: DialogService,
        public readonly marketplaceService: MarketplaceService,
        public readonly uiService: UiService,
        private readonly breakpointObserver: BreakpointObserver
    ) {
    }

    get isDesktop() : boolean {
        return this.breakpointObserver.isMatched('(min-width: 992px)');
    }

    ngOnInit() {
        this.setExpiresAt();
        this.showMobileBanner$ = this.uiService.tagsWithPartnersProductsSubject
            .pipe(map(s => !!s.find(t => t.tag.id === this.marketplaceService.getTags()?.[0].id)));
    }

    setExpiresAt(): void {
        const activeRewards = this.consumersService.rewards.filter((reward) => reward.status === 'active');
        if (activeRewards.length) {
            // Sort to get first expiresAt if more than one
            if (activeRewards.length > 1) activeRewards.sort((a, b) => new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime());
            this.expiresAt = activeRewards[0].expiresAt;
        }
    }

    public openHowItWorksDialog(): void {
        this.dialogService.openHowItWorksDialog();
    }
}
