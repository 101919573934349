import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AccountRoutingModule} from './account-routing.module';
import {LogoutComponent} from './logout/logout.component';
import {SharedModule} from '../../shared/shared.module';

// Angular material
import {SupportComponent} from './support/support.component';
import {PersonalInfosComponent} from './personal-infos/personal-infos.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {HistoryComponent} from './history/history.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatExpansionModule} from '@angular/material/expansion';
import {LoginComponent} from './login/login.component';
import {AuthComponent} from './auth/auth.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BecomeSponsorComponent} from './become-sponsor/become-sponsor.component';
import {SponsorshipComponent} from './sponsorship/sponsorship.component';

@NgModule({
    declarations: [
        AuthComponent,
        LogoutComponent,
        SupportComponent,
        PersonalInfosComponent,
        HistoryComponent,
        LoginComponent,
        SponsorshipComponent,
        BecomeSponsorComponent
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        // Angular material
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatTableModule,
        MatRippleModule,
        ClipboardModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatCheckboxModule
    ],
    exports: [
        AuthComponent
    ]
})
export class AccountModule {
}
