import {Component} from '@angular/core';
import {DialogService} from '../../../shared/services/dialog.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html'
})
export class TermsComponent {
    constructor(
        private readonly dialogService: DialogService
    ) {
        this.dialogService.closeAll();
    }

    return() {
        window.history.back();
    }
}
