import {debounceTime} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {Tag} from '@isifid/core';
import {ConsumersService} from '../../../../shared/services/consumers.service';
import {DialogService} from '../../../../shared/services/dialog.service';
import {MarketplaceService} from '../../../../shared/services/marketplace.service';
import {UiService} from '../../../../shared/services/ui.service';
import {ScrollerService} from '../../../../shared/services/scroller.service';
import {SearchService} from '../../../../shared/services/search.service';

@Component({
    selector: 'app-sidenav-tags',
    templateUrl: './tags.component.html',
    styleUrl: './tags.component.scss'
})
export class SidenavTagsComponent implements OnInit {
    currentTag: Tag = new Tag();
    tags: Array<Tag> = [];
    loadedTags: Array<Tag> = [];

    constructor(
        public readonly consumersService: ConsumersService,
        public readonly dialogService: DialogService,
        public readonly marketplaceService: MarketplaceService,
        public readonly uiService: UiService,
        private readonly scrollerService: ScrollerService,
        private readonly searchService: SearchService
    ) {
    }

    ngOnInit() {
        (document.getElementById('tags') as HTMLElement).style.top = this.searchService.hideSearchEngine ? '79px' : '154px';
        this.marketplaceService.tags.subscribe(tags => this.tags = tags);
        this.uiService.tagsWithPartnersProductsSubject.subscribe(s => this.loadedTags = s.map(t => t.tag));
        this.uiService.currentTagSubject
            .pipe(debounceTime(300))
            .subscribe((tag: Tag) => {
                if (tag?.id && tag.id !== this.currentTag.id) this.currentTag = tag;
            });
    }

    clickCategory(tag: Tag): void {
        this.currentTag = tag;
        this.scrollerService.scrollToPartnersSection(tag.id);
    }

    getTagImg(tagConfiguration: string): string {
        let configuration = tagConfiguration;
        if (!configuration) configuration = this.tags[0].configuration;
        try {
            return JSON.parse(configuration).imageUrl;
        } catch (e) {
            console.error(e);
            return '';
        }
    }
}
