<div class="container-fluid p-5 text-center">
    @if (logo()) {
        <div class="position-relative m-auto" style="min-height: 200px;">
            <img [ngSrc]="logo()"
                 (error)="uiService.handleMissingImage($event)"
                 [fill]="true"
                 [priority]="true"
                 alt="logo" class="object-fit-contain">
        </div>
    }
</div>
