<h1 class="text-title text-center my-5">Mentions légales</h1>

<div class="container px-2 px-lg-5 text-justify text-normal">
    @if (authenticationService.isAuthenticated() && marketplaceService.getClient().legalNotices) {
        <p [innerHTML]="marketplaceService.getClient().legalNotices"></p>
        <br>
    }
    Le présent site est édité et hébergé par ISIFID, société par actions simplifiée au capital de 222 222 €,
    immatriculée au registre de commerce de Nanterre sous le n° 834 210 312. Son siège social est situé au 9 rue des Colonnes,
    75002 Paris. Le représentant légal est Monsieur Georges Kammermann.
    <br><br>
    <h2 class="h2">Données à caractère personnel</h2>
    Les données à caractère personnel recueillies sont nécessaires à l’utilisation du service (envoi des bons d’achat,
    suivi des réclamations). Ces informations seront exploitées par ISIFID et ses sous-traitants éventuels pour cette
    finalité exclusivement.
    <br><br>
    Les données personnelles sont conservées pour une durée d’un an à compter de la date d’attribution de bons d’achat.
    A l’expiration de ce délai, les données seront détruites.
    <br><br>
    Vous disposez de droits d’accès, de rectification, d'effacement, de limitation, d’opposition, le cas échéant de
    portabilité et de définir des directives relatives à la conservation, à l’effacement et à la communication de vos
    données à caractère personnel après votre décès. Vous pouvez exercer ces droits en écrivant à ISIFID, 9 rue des Colonnes,
    75002 Paris, ou par email : <a href="mailto:rgpd@isifid.com">rgpd&#64;isifid.com</a>. Vous avez également le droit
    d'introduire une réclamation auprès de
    la CNIL (<a [href]="'https://www.cnil.fr'" target="_blank">www.cnil.fr</a>).
</div>
